import logo from './img/logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" />
        <p>
          The site is under construction
        </p>
        <p>
          Available soon
        </p>

      </header>
    </div>
  );
}

export default App;
